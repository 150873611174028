<template>
    <router-view />
</template>
<script>

export default {
  name: "Admin",
  components: {
  },
  data: function () {
    return {
    };
  },
};
</script>
